import React from 'react';
import styles from './AboutUs.module.css';  
import HeaderTop from './HeaderTop';
import Footer from './Footer';


const AboutUs = () => {
    return (
        <div className={styles.headerAbout}>
            <div className={styles.headerWrapper}>
                < HeaderTop />
                </div>
            <div>

            <div className={styles.aboutContainer}>
            <div className={styles.content}>
          <h1 className={styles.headingBirthday}>30 Years of Hospitality & Unforgettable Moments</h1>
          <img className={styles.line} src="home-line.png" alt="line"></img>
          <p className={styles.aboutText}>Our hotel opened its doors 30 years ago and has since become a place where the most memorable experiences are created. For three decades, we've strived to provide our guests with the highest level of comfort, luxury, and service. Thanks to your trust and support, we have become a hotel that stands as a symbol of hospitality and quality.</p>

<p className={styles.aboutText}>We take pride in being a part of your life – from romantic getaways to family celebrations and business events. Now, we invite you to join us in this special moment – our hotel's 30th anniversary – to share in the joy and celebration together.</p>

</div>
            </div>
            
            </div>
            <Footer/>
            </div>
  );
};

export default AboutUs;
