import React from 'react';
import styles from './TermsAndConditions.module.css';

const TermsAndConditions = () => {
  return (
    <div>
      <div className={styles.container}>
        <div className={styles.content}>
          <h1 className={styles.headingBirthday}>TERMS OF USE</h1>
          <img className={styles.line} src="home-line.png" alt="line"></img>
          <p className={styles.firstText}>Your access to and use of any content, including information, images, and text on the Network (collectively referred to as 'Material'), is subject to these Terms of Use and our Privacy Policy. By using the Network, you acknowledge and agree to abide by these Terms of Use and the Privacy Policy.</p>

<h2>1. Modifications to Terms of Use</h2>
<p className={styles.text}>These Terms of Use are the most current and replace any prior versions. The Network reserves the right to amend these Terms of Use at any time by posting updated terms on the Network. You recognize that such updates constitute adequate notice of changes, and you agree to comply with the modified Terms of Use. Since these Terms of Use apply to you, it is your responsibility to review them periodically.</p>

<h2>2. Network Information</h2>
<p className={styles.text}> The Network strives to ensure that the Material is accurate and error-free, but it does not guarantee the accuracy, completeness, or reliability of the Material. The Network is intended to provide general information and does not offer professional advice. Information on the Network may be outdated or incorrect.</p>

<h2>3. Communication Facilities</h2>
<p className={styles.text}>3.1 As a condition of using any Communication Facility and accessing the Network, you agree not to:</p>
<ul>
    <li>Post or transmit any illegal, threatening, abusive, defamatory, obscene, vulgar, pornographic, profane, or otherwise inappropriate content, including material that could be considered criminal or legally questionable;</li>
    <li>Post or transmit any content that infringes on others' rights without permission from the relevant rights holder;</li>
    <li>Post or transmit any material containing viruses or harmful components;</li>
    <li>Use any material for commercial purposes, including any promotional or advertising content.</li>
</ul>

<p className={styles.text}>3.3 The Network may periodically monitor or review Communication Facilities and reserves the right to edit, reject, or remove any material deemed objectionable or in violation of applicable laws or these Terms of Use. The Network is not obligated to monitor Communication Facilities or to edit, remove, or refuse content. You acknowledge that Communication Facilities are public forums.</p>

<p className={styles.text}>3.4 The Network reserves the right to cooperate with law enforcement authorities to comply with legal requests to disclose the identity or information about individuals posting content that violates relevant laws.</p>

<h2>4. Third-Party Links</h2>
<p className={styles.text}>The Network may include links to third-party websites (Third Party Websites). The Network does not endorse or approve these Third Party Websites or their operators.</p>
</div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
