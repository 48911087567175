import React from 'react';
import { Link } from 'react-router-dom';
import styles from './MobileMenuModal.module.css';

const MobileMenuModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay} onClick={onClose}>
      <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
        {/* Кнопка закрытия модального окна */}
        <button className={styles.closeButton} onClick={onClose}>×</button>

        {/* Навигация меню */}
        <nav className={styles.navMenu}>
          <Link to="/" className={styles.navItem} onClick={onClose}>Home</Link>
          <Link to="/offers" className={styles.navItem} onClick={onClose}>Offers</Link>
          <Link to="/aboutus" className={styles.navItem} onClick={onClose}>About Us</Link>
          <Link to="https://Perth-30.com/thankyoupage/thankyoupage.html"
            target="_blank" rel="noopener noreferrer" className={styles.navItem} onClick={onClose}>Contact Us</Link>
          <Link to="/policies" className={styles.navItem} onClick={onClose}>Policies</Link>
        </nav>
      </div>
    </div>
  );
};

export default MobileMenuModal;