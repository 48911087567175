import React from 'react';
import styles from './Footer.module.css';
import FooterJoinLink from './FooterJoinLink';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className={styles.footer}>
      <Link
        to="https://Perth-30.com/thankyoupage/index.php"
        target="_blank" 
        rel="noopener noreferrer"><div className={styles.overlay}></div></Link>

      <div className={styles.footerContent}>
        <div className={styles.footerLeft}>
          <Link
            to="https://Perth-30.com/thankyoupage/index.php"
            target="_blank" 
            rel="noopener noreferrer"> <img className={styles.footerImg} src="footer-img.svg" alt="FooterImage" /></Link>
        </div>
        <div className={styles.footerRight}>
          <h1>Get Access to CR30WN's Exclusive Offers</h1>

          <p className={styles.text}>Join our exclusive community and enjoy the perks of insider knowledge</p>
          <FooterJoinLink />
          <div className={styles.footerLinks}>
            <Link
              to="https://Perth-30.com/thankyoupage/thankyoupage.html"
            target="_blank" 
      rel="noopener noreferrer">Contact us</Link>
            <Link to="/termsandconditions">TERMS OF USE</Link>
            <Link to="/"  >Home</Link>
          <Link to="/offers" >Offers</Link>
          <Link to="/aboutus"  >About Us</Link>
          <Link to="https://Perth-30.com/thankyoupage/thankyoupage.html"
            target="_blank" rel="noopener noreferrer" className={styles.navItem} >Contact Us</Link>
          <Link to="/policies" >Policies</Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
