import React from 'react';
import styles from './AboutUs.module.css';  
import HeaderTop from './HeaderTop';
import Footer from './Footer';


const Policies = () => {
    return (
        <div className={styles.headerAbout}>
            <div className={styles.headerWrapper}>
                < HeaderTop />
                </div>
            <div>

            <div className={styles.aboutContainer}>
            <div className={styles.content}>
                        <h1 className={styles.headingBirthday}>Privacy Policy</h1>
                        <img className={styles.line} src="home-line.png" alt="line"></img>
                         <h2 className={styles.abouth2}>Your Right to Privacy</h2>
                        
                        <p className={styles.aboutText}>Your privacy is essential to us, and Network is committed to safeguarding your personal information across all areas of our operations. Personal information includes any data or opinions related to you.</p>

                        <p className={styles.aboutText}>This Privacy Policy outlines how Network (referred to as "we" or "our") manages the personal information of our guests and suppliers (referred to as "you" or "your"). It explains how we collect, use, disclose, and store your personal information. Specific details related to particular products or services may also be found in our Privacy Collection Statements.</p>
                        
                        <p className={styles.aboutText}>This policy includes information on how you can access the personal information we hold about you, request corrections, and lodge a complaint if you have concerns about how we handle your information.</p>

                        <p className={styles.aboutText}>For additional information, please contact Network (see the "How to Contact Network" section). If we update our practices regarding personal information, we will revise this policy on our websites.</p>
                        
                        <h2 className={styles.abouth2}>Scope of the Policy</h2>
                        <p className={styles.aboutText}>This Privacy Policy applies to Network Resorts Limited and its subsidiaries, including Network Melbourne Limited, Burswood Nominees Limited, Network Sydney Gaming Pty Ltd, and Network Sydney Property Pty Ltd (collectively referred to as "Network," "we," or "our"). It covers the collection, use, disclosure, and storage of personal information related to individuals such as guests and suppliers. This policy does not apply to personal information of Network employees or contractors</p>

                        <h2 className={styles.abouth2}>Collection of Personal Information</h2>
                        <p className={styles.aboutText}>Network collects your personal information (directly or through related companies) to perform our functions and inform you about our services. Our functions encompass various activities, including casinos, the Network Signup loyalty program, hotels, restaurants, entertainment, and more.</p>
                        <p className={styles.aboutText}>Personal information may include your name, image, date of birth, phone numbers, postal address, and email address. The type of information collected depends on your interaction with us and may include:</p>
                        <ul>
                            <li>Name, title, gender, date of birth, and identification details (e.g., passport, driver’s license);</li>
                            <li>Contact information, including home address, phone number, and email;</li>
                            <li>Payment information, such as credit or debit card numbers;</li>
                            <li>CCTV images from our casinos and resorts;</li>
                            <li>Health and dietary information relevant to our services.</li>
                        </ul>
                        <p className={styles.aboutText}>If Network does not collect your personal information, we may not be able to provide you with certain services or access to specific areas of our premises.</p>

                        <h2 className={styles.abouth2}>Collection of Sensitive Personal Information</h2>
                        <p className={styles.aboutText}>Sensitive personal information includes data regarding racial or ethnic origin, political opinions, religious beliefs, sexual preferences, health information, and membership in trade unions.</p>
                        <p className={styles.aboutText}>Network typically does not collect sensitive information. However, in limited circumstances, we may collect such information with your consent or if required by law, for example, when you request medical assistance.</p>

                        <h2 className={styles.abouth2}>How We Collect Your Personal Information</h2>
                        <p className={styles.aboutText}> We generally collect your personal information directly from you when you stay at a Network hotel, join Network Signup, participate in competitions, or make reservations.</p>
                        <p className={styles.aboutText}> Network may also collect information from public sources and third parties, such as banks, law enforcement agencies, and regulatory bodies.</p>
                       
                        <h2 className={styles.abouth2}>Using and Disclosing Your Personal Information</h2>
                        <p className={styles.aboutText}>Network uses and discloses your personal information for various purposes, including:</p>
                        
                        <ul>
                            <li>Identifying and managing our relationship with you;</li>
                            <li>Marketing purposes, such as informing you about updates, offers, and events;</li>
                            <li>Administering our services, including membership programs;</li>
                            <li>Improving customer experiences</li>
                            <li>Ensuring responsible gaming and maintaining security in our venues.</li>
                        </ul>
                        <p className={styles.aboutText}>We prioritize the confidentiality of your information and will disclose it only for the purposes it was collected, or as required by law.</p>

                        <h2 className={styles.abouth2}>Disclosing Your Personal Information Overseas</h2>
                        <p className={styles.aboutText}>Network may share your personal information with overseas recipients in countries where Network or our service providers operate. This is more likely if you are a customer residing outside of Australia.</p>


                        <h2 className={styles.abouth2}>Marketing</h2>
                        <p className={styles.aboutText}>With your consent, Network may use your personal information to contact you regarding news, offers, and services that may interest you. You can update your marketing preferences at any time.</p>

                        <h2 className={styles.abouth2}>Surveillance</h2>
                        <p className={styles.aboutText}>For safety and security, all movements within Network venues are subject to video and audio monitoring. Suspicious activities may be reported to law enforcement or regulatory bodies.</p>

                        <h2 className={styles.abouth2}>Security of Personal Information</h2>
                        <p className={styles.aboutText}>Network takes steps to protect your personal information against loss, unauthorized access, and misuse. We maintain confidentiality through employee training and secure data handling practices.</p>

                        <h2 className={styles.abouth2}>Access and Correction of Your Personal Information</h2>
                        <p className={styles.aboutText}>You can request access to your personal information held by Network. To do this, please contact us and complete the necessary forms. We will provide a copy within 30 days unless otherwise exempted by law.</p>

                        <h2 className={styles.abouth2}>Websites, Devices, and Cookies</h2>
                        <p className={styles.aboutText}>Network may collect data from your devices when you connect to our networks. This includes usage data, device information, and cookies that track your online activity.</p>

                        <h2 className={styles.abouth2}>Making a Privacy Complaint</h2>
                        <p className={styles.aboutText}>If you believe Network has breached the Privacy Act 1988, you can submit a written complaint. We will acknowledge receipt of your complaint within 7 days and respond within 28 days.
                            For further details, please refer to the "How to Contact Network" section.</p>
                        <p className={styles.aboutText}>__________________________</p><br></br>

                        <h1 className={styles.headingBirthday}>Cookies Policy</h1>
                        <img className={styles.line} src="home-line.png" alt="line"></img>
                        <p className={styles.aboutText}>We use cookies to enhance the functionality of our website, communications, and other online services, making them more useful and personalized to your experience.</p>
                        <p className={styles.aboutText}>By using this website and our online services, you consent to our storing and accessing cookies and data regarding your use of our website and/or digital services as outlined on this page</p>
                        <p className={styles.aboutText}>You can find more information about cookies, how we use them, and how to adjust your cookie settings below</p>

                        <h2 className={styles.abouth2}>What are cookies?</h2>
                        <p className={styles.aboutText}>A cookie is a small text file placed on your device when you interact with online content (such as this website) that enables us to recognize and remember your browser ID.</p>

                        <h2 className={styles.abouth2}>How we use cookies</h2>
                        <p className={styles.aboutText}>When you visit our site, cookies gather and store information about how you use our website.</p>
                        <p className={styles.aboutText}>The information collected from cookies helps us provide a better experience by remembering your preferences as you browse. Certain types of cookies also perform essential functions to improve the website's performance.</p>
                        <p className={styles.aboutText}>Information collected through cookies may identify or relate to a specific individual. In some cases, the information gathered from cookies may be combined with data that identifies an end user. Any personal information collected via cookies is handled in accordance with Network’s Privacy Policy.</p>
                        <p className={styles.aboutText}>We use cookies to:</p>
                        <ul>
                            <li>Enable essential site features and support services on our website.</li>
                            <li>Improve the performance and usability of our website.</li>
                            <li>Deliver more relevant products and services to you.</li>
                            <li>Enhance communications and Signup programs you have opted into.</li>
                            <li>Personalize content and ads.</li>
                            <li>Provide social media features.</li>
                            <li>Analyze site behavior and traffic, sometimes correlating it with calls or chats you make to us.</li>
                            <li>Build an aggregated profile of website visitors.</li>
                            <li>Advertise promotions and value-added products and services to our customers, including behavioral advertising.</li>
                        </ul>

                        <p className={styles.aboutText}>We also share information about your website usage with our social media, advertising, and analytics partners, who may combine it with other information you’ve provided to them or collected from your use of their services.</p>

                        <h2 className={styles.abouth2}>Types of cookies we use</h2>
                        <p className={styles.aboutText}>Our website uses persistent cookies and session cookies:</p>
                        <ul>
                            <li><span className={styles.aboutBold}>Persistent cookies</span> remain on your device for a specified period. For example, we use persistent cookies to recognize you each time you visit our website and remember your preferences.</li>
                            <li><span className={styles.aboutBold}>Session cookies</span> are created when you visit our website and are automatically deleted when you close your browser. We use these cookies to link your actions during a single session to ensure reliable website performance.</li>
                        </ul>

                        <p className={styles.aboutText}>Some of our partners may also set third-party cookies on your device when you visit our website. We and our partners may use these cookies to provide more relevant advertising, better understand our users, keep our website functioning reliably, and help us deliver our services.</p>

                        <h2 className={styles.abouth2}>Controlling and deleting cookies on your device</h2>
                        <p className={styles.aboutText}>You consent to our use of cookies if you continue to use our website without disabling them in your browser. You can manage your cookie settings through your browser. If you disable cookies, some areas of our website may not function properly or be accessible.</p>
                        <p className={styles.aboutText}>For further information on managing or disabling cookies in your browser, please refer to the following</p>
                        <ul>
                            <li>Click here for Google Chrome</li>
                            <li>Click here for Internet Explorer</li>
                            <li>Click here for Firefox</li>
                             <li>Click here for Safari</li>
                        </ul>
                        <h2 className={styles.abouth2}>What is behavioral advertising?</h2>
                        <p className={styles.aboutText}>Online behavioral advertising (also known as interest-based advertising) serves advertisements on the websites you visit, making them more relevant to your interests.</p>
                        <p className={styles.aboutText}>Behavioral advertising works by grouping web users with shared interests based on their previous browsing activity and serving advertisements to those users that match their interests. This way, advertising becomes as relevant and useful as possible.</p>
                        <p className={styles.aboutText}>Relevant advertisements can also be delivered based on the content you have just viewed. This is known as retargeting.</p>
                        <p className={styles.aboutText}>__________________________</p><br></br>
                        <h1 className={styles.headingBirthday}>TERMS OF USE</h1>
                        <img className={styles.line} src="home-line.png" alt="line"></img>
                        <p className={styles.aboutText}>Your access to and use of any content, including information, images, and text on the Network (collectively referred to as 'Material'), is subject to these Terms of Use and our Privacy Policy. By using the Network, you acknowledge and agree to abide by these Terms of Use and the Privacy Policy</p>
                        <h2 className={styles.abouth2}>1. Modifications to Terms of Use</h2>
                        <p className={styles.aboutText}>    These Terms of Use are the most current and replace any prior versions. The Network reserves the right to amend these Terms of Use at any time by posting updated terms on the Network. You recognize that such updates constitute adequate notice of changes, and you agree to comply with the modified Terms of Use. Since these Terms of Use apply to you, it is your responsibility to review them periodically.</p>

                        <h2 className={styles.abouth2}>2. Network Information</h2>
                        <p className={styles.aboutText}> The Network strives to ensure that the Material is accurate and error-free, but it does not guarantee the accuracy, completeness, or reliability of the Material. The Network is intended to provide general information and does not offer professional advice. Information on the Network may be outdated or incorrect.</p>

                        <h2 className={styles.abouth2}>3. Communication Facilities</h2>
                        <p className={styles.aboutText}>3.1 s a condition of using any Communication Facility and accessing the Network, you agree not to: </p>

                        <p className={styles.aboutText}>Post or transmit any illegal, threatening, abusive, defamatory, obscene, vulgar, pornographic, profane, or otherwise inappropriate content, including material that could be considered criminal or legally questionable; </p>
                        <p className={styles.aboutText}>Post or transmit any content that infringes on others' rights without permission from the relevant rights holder;</p>
                        
                       <p className={styles.aboutText}> Post or transmit any material containing viruses or harmful components;
                            Use any material for commercial purposes, including any promotional or advertising content.</p>
                        <p className={styles.aboutText}>3.3 The Network may periodically monitor or review Communication Facilities and reserves the right to edit, reject, or remove any material deemed objectionable or in violation of applicable laws or these Terms of Use. The Network is not obligated to monitor Communication Facilities or to edit, remove, or refuse content. You acknowledge that Communication Facilities are public forums. </p>

                        <p className={styles.aboutText}>3.4  The Network reserves the right to cooperate with law enforcement authorities to comply with legal requests to disclose the identity or information about individuals posting content that violates relevant laws.</p>

                        <h2 className={styles.abouth2}>Third-Party Links</h2>
                        <p className={styles.aboutText}>The Network may include links to third-party websites (Third Party Websites). The Network does not endorse or approve these Third Party Websites or their operators.</p>

                        <h1 className={styles.headingBirthday}>Disclaimer</h1>
                        <img className={styles.line} src="home-line.png" alt="line"></img>
                        <p className={styles.aboutText}>The information provided on this website (the "Site") is for general informational purposes related to the organization of events in hotels. The owners and administrators of the Site do not guarantee the accuracy, completeness, or timeliness of the information presented.</p>
                        <p className={styles.aboutText}>Event Organization: All details regarding event venues, services provided by hotels, and other event-related aspects are subject to change without prior notice. We are not responsible for any changes made by the hotels or third-party service providers.</p>
                        <p className={styles.aboutText}>No Guarantee of Availability: While we strive to ensure that the information on available venues, dates, and services is up-to-date, we cannot guarantee availability at any particular time. Please confirm all arrangements directly with the hotel or service provider.</p>
                        <p className={styles.aboutText}>Third-Party Services: The Site may include links to third-party websites or services. We do not endorse or take responsibility for the content, policies, or practices of any third-party websites or services. Any interactions or transactions with these third parties are at your own risk.</p>
                        <p className={styles.aboutText}>Liability: We are not liable for any direct, indirect, incidental, or consequential damages arising from the use or inability to use the Site, or from any errors or omissions in the information provided.</p>
                        <p className={styles.aboutText}>Event Participation: Participation in any events organized through this Site is at your own risk. We are not responsible for any personal injury, property damage, or other liabilities that may arise during or as a result of your participation in these events.</p>
                        <p className={styles.aboutText}>By using this Site, you acknowledge that you have read, understood, and agreed to this disclaimer. If you do not agree with any part of this disclaimer, please refrain from using the Site.</p>
                    </div>
            </div>
            
            </div>
            <Footer/>
            </div>
  );
};

export default Policies;
