import React from 'react';
import styles from './HeaderTop.module.css';
import { Link } from 'react-router-dom';
import MobileLink from './MobileLink';
import LoginLink from './LoginLink';


const HeaderTop = () => {
  return (
      <div className={styles.headerTop}>
        {/* <div className={styles.links}>
          <Link to="/">HOME</Link>
        </div> */}

        <MobileLink/>
        <Link
        to="https://Perth-30.com/thankyoupage/index.php"
        target="_blank" 
        rel="noopener noreferrer"><img className={styles.logoHeader} src='/logo.svg' alt="Logo"></img></Link>
        <LoginLink />
      </div>
  );
};

export default HeaderTop;
