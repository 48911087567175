import React, { useState } from 'react';
import MobileMenuModal from './MobileMenuModal';
import styles from './MobileLink.module.css';

const MobileLink = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleCloseMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <>
      <button onClick={handleMenuToggle} className={styles.mobileLink}>
        <img src='/mobile-menu.svg' alt='menu'></img>
      </button>
      <MobileMenuModal isOpen={isMenuOpen} onClose={handleCloseMenu} />
    </>
  );
};

export default MobileLink;
