import React from 'react';
import styles from './ReadMore.module.css';

const ReadMore = () => {
  return (
    <div>
      <div className={styles.container}>
        <div className={styles.content}>
          <h2 className={styles.headingOffer}>OFFER</h2>
          <h1 className={styles.headingBirthday}>BIRTHDAY SURPRISE</h1>
          <img className={styles.line} src="home-line.png" alt="Decorative Line" />
          <h2 className={styles.headingDate}>29 AUGUST – 21 OCTOBER</h2>
          <h3 className={styles.headingExclusive}>Exclusively for CR30WN Rewards Members</h3>
          <p className={styles.text}> Crown is celebrating its 30th anniversary, and we're marking the occasion with excitement!<br />Use your Crown Rewards Card to automatically enter our Draw</p>
          <h4 className={styles.headingWinner}>Every Thursday at 6:30 pm, we’ll reveal a lucky winner in the Atrium.</h4>
          <p className={styles.text} > <span className={styles.text2}>Come celebrate with us and you might just win a special birthday surprise!</span></p>
          <h5 className={styles.headingDraw}>Draw Schedule:</h5>
          <div className={styles.weeks}>
            {[
              { number: 'Week 1', date: '6am Friday, 29 August – 5:59am Thursday, 4 September 2024' },
              { number: 'Week 2', date: '6am Friday, 5 September – 5:59am Thursday, 11 September 2024' },
              { number: 'Week 3', date: '6am Friday, 12 September – 5:59am Thursday, 18 September 2024' },
              { number: 'Week 4', date: '6am Friday, 19 September – 5:59am Thursday, 25 September 2024' },
              { number: 'Week 5', date: '6am Friday, 26 September – 5:59am Thursday, 3 October 2024' },
              { number: 'Week 6', date: '6am Friday, 4 October – 5:59am Thursday, 10 October 2024' },
              { number: 'Week 7', date: '6am Friday, 11 October – 5:59am Thursday, 17 October 2024' },
              { number: 'Week 8', date: '6am Friday, 18 October – 5:59am Thursday, 21 October 2024' },
            ].map((week, index) => (
              <div key={index} className={styles.weekContainer}>
                <div className={styles.weekNumber}>{week.number}</div>
                <div className={styles.weekDescription}>{week.date}</div>
              </div>
            ))}
          </div>
          <h3 className={styles.headingBottom}>Will you be our next Crown Birthday Winner?</h3>
          <h4 className={styles.headingWinner}>From the perspective of our policy department, the following details outline the terms and conditions of the Network Signup Card</h4>

          <img className={styles.line} src="home-line.png" alt="Decorative Line" />

          <p className={styles.text}>1. <span className={styles.text2}>Registration and Participation:</span> When we refer to the Network Signup Card, we mean that the client will be officially registered in our event database. This registration grants access to exclusive participation in our ongoing celebrations.</p>
          <p className={styles.text}>2. <span className={styles.text2}> Event Access: </span> The Network Signup Card allows the registered individual to attend and participate in daily offline events held throughout the event period, which runs from August 29 to October 21. This provides an opportunity to be part of our vibrant community and enjoy the full range of activities organized at the venue.</p>
          <p className={styles.text}>3. <span className={styles.text2}> Benefits and Offers: </span> Registered cardholders will be entitled to enjoy free delicious dining and happy hour specials across our complex during this period. These offerings have been designed to provide an exceptional experience to our participants, ensuring they feel valued and appreciated.</p>
          <p className={styles.text}>4. <span className={styles.text2}> One-Time Offer: </span> Please note that all the above benefits, including free dining and happy hour specials, are offered only once per registered client. Each individual is eligible for this offer only once during the event period, ensuring a fair and equitable experience for all participants.</p>
           <p className={styles.text}>By registering with the Network Signup Card, clients agree to these terms and are encouraged to make the most of this unique opportunity.</p>
        </div>
      </div>
    </div>
  );
};

export default ReadMore;
