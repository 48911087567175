import React from 'react';
import styles from './AboutUs.module.css';  
import HeaderTop from './HeaderTop';
import Footer from './Footer';


const Offers = () => {
    return (
        <div className={styles.headerAbout}>
            <div className={styles.headerWrapper}>
                < HeaderTop />
                </div>
            <div>

            <div className={styles.aboutContainer}>
            <div className={styles.content}>
                        <h1 className={styles.headingBirthday}>From the perspective of our policy department, the following details outline the terms and conditions of the Network Signup Card</h1>
                        
                        <img className={styles.line} src="home-line.png" alt="line"></img>
                        
          <p className={styles.aboutText}>1.Registration and Participation: When we refer to the Network Signup Card, we mean that the client will be officially registered in our event database. This registration grants access to exclusive participation in our ongoing celebrations.</p>

                        <p className={styles.aboutText}>2. Event Access: The Network Signup Card allows the registered individual to attend and participate in daily offline events held throughout the event period, which runs from August 29 to October 21. This provides an opportunity to be part of our vibrant community and enjoy the full range of activities organized at the venue.</p>
                        
                        <p className={styles.aboutText}>3. Benefits and Offers: Registered cardholders will be entitled to enjoy free delicious dining and happy hour specials across our complex during this period. These offerings have been designed to provide an exceptional experience to our participants, ensuring they feel valued and appreciated.</p>

                        <p className={styles.aboutText}>4. One-Time Offer: Please note that all the above benefits, including free dining and happy hour specials, are offered only once per registered client. Each individual is eligible for this offer only once during the event period, ensuring a fair and equitable experience for all participants.</p>
                        
                        <h2 className={styles.abouth2}>By registering with the Network Signup Card, clients agree to these terms and are encouraged to make the most of this unique opportunity.</h2>

</div>
            </div>
            
            </div>
            <Footer/>
            </div>
  );
};

export default Offers;
