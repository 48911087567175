import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Header.module.css';
import HeaderBottom from './HeaderBottom';
// import LoginLink from './LoginLink';
// import MobileLink from './MobileLink';
import JoinLink from './JoinLink';
import HeaderTop from './HeaderTop';

const Header = () => {
  return (
    <div className={styles.header}>
      <Link
      to="https://Perth-30.com/thankyoupage/index.php"
      target="_blank" 
      rel="noopener noreferrer">
      <div className={styles.overlay}>
        <div className={styles.overlayTop}></div>
        <div className={styles.overlayBottom}></div>
      </div>
      </Link>
    
      < HeaderTop />

      {/* <div className={styles.headerTop}>
        <div className={styles.links}>
          <Link to="/">HOME</Link>
        </div>

        <MobileLink/>
        <Link
        to="https://Perth-30.com/thankyoupage/index.php"
        target="_blank" 
        rel="noopener noreferrer"><img className={styles.logoHeader} src='/logo.svg' alt="Logo"></img></Link>
        <LoginLink />
      </div> */}

      <HeaderBottom />
        <JoinLink />
    </div>
  );
};

export default Header;
