import React from 'react';
import styles from './Header.module.css';


const HeaderBottom = () => {
  return (
      <div className={styles.headerBottom}>
        <img className={styles.line} src="/line.png" alt="Line"></img>
        <h1>CELEBRATE 30 YEARS OF<br />CROWN Hotels</h1>
      
      </div>
  );
};

export default HeaderBottom;
